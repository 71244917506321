@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;900&display=swap');

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
	background-image: url("images/bgr.jpg");
  	background-repeat: no-repeat;
 	background-attachment: fixed;
	background-size: cover;
	color: white;
	font-family: 'Roboto', sans-serif;
	font-weight: 900
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
.full{ float: left; width: 100%; }
.left{ float: left; } .right{ float:right; }
.center{ text-align: center;}
.sidebar{
	float: left;
	width: 332px;
	position: fixed;
}
.right_side{
	float: right;
	width: calc(100% - 5% - 332px);
	box-sizing: border-box;
	padding-right: 20px;
}
.logo{
	margin-top: 40px;
}
.menu a{
	font-size: 32px;
	color: #fff;
	text-decoration: none;	
}
.menu li{
	padding: 12px 0px;
	
}
.menu li:hover,.menu .ant-menu-item-selected{
	background: url("images/menu_selected.png") left center no-repeat;
}
.menu{
	padding-top: 38px;
}
.socials li, .socials a{
	display: inline;
	height: 100%;
	vertical-align: middle
}
.socials li{
	padding-left: 15px;
	padding-right: 15px;
}
.socials {
	margin-top: 55px;
}
.right_side .top{
	margin-top: 40px;
}
.right_side .top a{
	background: white;
	color: #2e2e2e;
	padding: 10px 30px;
	font-size: 11px;
	border-radius: 25px;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin-left: 27px;
	text-decoration: none;
}
.right_side .top a.connect{
	cursor: pointer;
	background: #fff url("images/connect_icon.png") 94% 50% no-repeat;
	padding-right: 35px;
}
.content{
	margin-top: 25px;
}
.information .heading{
	margin-bottom: 45px;
}
.text_holder{
	font-weight: 300;
	text-align: center;
	font-size: 16px;
	line-height: 22px;
	box-sizing: border-box;
	padding: 0px 10%;
}
.text_holder p{
	padding-bottom: 20px;
	text-shadow: -1.696px 1.06px 0px rgba(65, 65, 65, 0.61);
}
.text_holder strong{
	font-weight: 900;
}
.mint_instructions{
	height: 104px;
	position: relative;
}
.mint_instructions ul{
	display: inline-block;
	vertical-align: middle;
	
}
.mint_instructions li{
	display: inline-block;
	background: url("images/steps_bgr.png") center center no-repeat;
	box-sizing: border-box;
	width: 292px;
	vertical-align: middle;
	height: 100%;
	padding: 41px 0px;
	text-transform: uppercase;
	font-size: 16px;
	line-height: 20px;
	text-shadow: -1.696px 1.06px 0px rgba(65, 65, 65, 0.61);
}

.instruction_arrows{
	position: absolute;
	width: 100%;
	text-align: center;
	top: 30px;
}
.mint_redirect{
	margin-top: 120px;
}
.mint_button{
	display: inline-block;
	background: url("images/mint_button_background.png");
	height: 63px;
	width: 186px;
	text-align: center;
	font-size: 18px;
	text-transform: uppercase;
	letter-spacing: 6px;
	line-height: 65px;
	color: #fff;
	text-decoration: none;
	cursor: pointer;
}
.prize_collection, .minting{ position: relative; }
.prize_collection .icon{
	position: relative;
	width: 120px;
	z-index: 2;
}
.prize_collection .collection_details{
	width: calc(100% - 100px);
	max-width: 1366px;
	position: absolute;
	left: 106px;
	top: 33px;
	background: url("images/prize_strip_bgr.png") center right no-repeat;
	height: 108px;
	z-index: 1;
	max-width: 1360px;
}
.collection_details li{
	width: 23%;
	float:left;
	text-align: center;
	padding-top: 40px;
	text-shadow: -1.696px 1.06px 0px rgba(65, 65, 65, 0.66);
}
.collection_details li:nth-child(1){
	text-align: left;
	box-sizing: border-box;
	padding-left: 30px;
}
.minting_image{
	position: relative;
	margin-top: 40px;
}
.minting_frame{
	width: 100%;
	position: absolute;
	background: url("images/mint_border.png") top center no-repeat;
	top: 240px;
	left: 0px;
	height: 374px;
	z-index: 1;
}
.mint_supply, .mint_cost{
	width: 46%; 
	float: left;
	margin-top: 202px;
}
.mint_cost{
	float: right;
}
.minting_button{
	position: relative;
	z-index: 2;
	margin-top: -26px;
}
.minting_band{
	z-index: 1;
	margin-top: -90px;
}
.minting_band img{
	width: 98%;
}
.nft_entry{
	float: left;
	width: 235px;
	text-align: center;
	position: relative;
}
.nft_count{
	background: url("images/collection_counter_bgr.png");
	width: 41px;
	height: 58px;
	position: absolute;
	box-sizing: border-box;
	top: 133px;
    left: 160px;
	padding-top: 20px;
	transform: rotate(-30deg);
  -webkit-transform: rotate(-30deg);
  -moz-transform: rotate(-30deg);
  -ms-transform: rotate(-30deg);
  -o-transform: rotate(-30deg);
	font-size: 22px;
}
.minting_info{
	margin-top: 30px;
    margin-bottom: 30px;
}
.comingsoon{
	padding-top: 200px;
}
.comingsoon .mint_redirect{
	margin-top: 30px;
}
body::-webkit-scrollbar {
  width: 1em;
	background: #2e2020;
}
body::-webkit-scrollbar-thumb {
	background:url("images/scroll.png") top center;
	background-size: cover;
}
.mobile_logo{
	display: none;
}
.mobile_menu_burger{
	display: none;
}
.mobile_menu{
	display: none;
}
@media only screen and (max-width: 1200px) {
	.information .instruction_arrows{
		display: none;
	}
}

@media only screen and (max-width: 1000px) {
	body{
		background-position: 32% 0%;
	}
	.sidebar{
		display: none;
	}
	.right_side{
		width: 100%;
		padding: 0px;
		
	}
	.mint_instructions li{
		float: left;
		width: 100%;
	}
	.mint_instructions{
		height: auto;
	}
	.mint_redirect{
		margin-top: 40px;
		margin-bottom: 100px;
	}
	.information .heading img{
		width: 100%;
	}
	.mobile_logo{
		display: block;
		margin-top: 40px;
	}
	.right_side .top a{ display: none; }
	.right_side .top a.connect{ display: block; width: 85%; }
	.top{ width: 100%; text-align: center; }
	.mobile_menu_burger{
		background:url("images/menuicon.png") center center no-repeat;
		display: block;
		position: fixed;
		top: 20px;
		left: 20px;
		width: 60px;
		height: 60px;
		font-size: 0px;
		line-height: 0px;
		z-index: 12;
	}
	.mobile_menu{
		position: fixed;
		z-index: 11;
		width: 100%;
		height: 100%;
		display: block;
		background: url('images/black_p.png');
		text-align: center;
		font-size: 26px;
		line-height: 52px;
		padding-top: 100px;
	}
	.mobile_menu .ant-menu-item a{
		color: #fff;
		width: 100%;
		float: left;
		padding: 10px 0px;
		text-decoration: none;
		
	}
	.mobile_menu .ant-menu-item a:hover, .mobile_menu .ant-menu-item-selected{
		background: url('images/mobile_hover.png') center center no-repeat;
	}
	.comingsoon{
		padding-top: 100px;
	}
	.minting_content{
		padding-bottom: 100px;
	}
	.minting_frame{
		background: url('images/mint_border_mobile.png') bottom center no-repeat;
		margin-top: 60px;
		margin-bottom: 60px;
	}
	.mint_supply, .mint_cost{
		width: 100%;
		margin-top: 0px;
		margin-bottom: 10px;
	}
	.minting_image{
		margin-top: 87px;
	}
	.minting_band{
		display: none;
	}
	.nft_entry{
		text-align: center;
		width: 100%;
	}
	.collection .headline img{
		display: none;
		
	}
	.collection .headline
	{
		width: 100%;
		background: url('images/mint_header.png') top center no-repeat;
		height: 250px;
	}
	.collection_list{
		padding-bottom: 100px;
	}
	.prize_collection .icon{
		width: 100%;
		text-align: center;
	}
	.prize_collection .collection_details{
		position: static;
		width: 100%;
		background: none;
		height: auto;
		padding-bottom: 50px;
		float: left;
	}
	.collection_details li{
		width: 50%;
	}
	.nft_count{
		left: auto;
		right: 31%;
	}
}












